<template>
  <div>
    <multiselect v-model="val"
                 v-bind:options="options"
                 placeholder=""
                 v-bind:label="label"
                 v-bind:track-by="trackBy"
                 v-bind:multiple="true"
                 v-bind:taggable="true"
                 v-bind:searchable="searchable"
                 v-bind:loading="isLoading"
                 v-bind:show-no-results="false"
                 noOptions="Список пустой"
                 selectLabel="Выбрать"
                 deselectLabel="Удалить"
                 selectedLabel="Выбран"
                 v-on:search-change="onSearch"
                 v-bind:disabled="isDisabled"></multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
  components: {
    Multiselect
  },
  props: {
    value: [String, Array, Object],
    options: Array,
    searchable: Boolean,
    trackBy: {
      default: 'code',
      type: String
    },
    label: String,
    name: String
  },

  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    ...mapGetters({
      inheritedFields: 'partners/getInheritedFields',
      parentId: 'partners/getParentId'
    }),

    val: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },

    isDisabled() {
      return this.inheritedFields.includes(this.name) && (this.parentId != null || this.$route.query.copyFromId != null);
    }
  },

  methods: {
    onSearch(v) {
      this.$emit('onSearch', v);
    },
    changeLoadingState(v) {
      this.isLoading = v;
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  .multiselect__tags {
    min-height: 40px;
    display: block;
    padding: .6rem 4rem 0 .6rem;
  }

  .multiselect__tag {
    padding: 6px 26px 6px 10px;
    margin-right: 5px;
    background: $primary;
    margin-bottom: 5px;
    font-size: 13px;
    vertical-align: top;
  }

  .multiselect__tag-icon:after {
    color: #fff;
    line-height: 24px;
  }
  
  .multiselect__option {
    font-size: 1.4rem;
    padding: 1rem 1.4rem;
    min-height: 1px;

    &:after {
      line-height: 3.6rem;
    }
  }
</style>
